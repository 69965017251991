import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.PROFILE_SET_SELECTED_TAB:
      return { ...state, selectedTab: action.payload };
    default:
      return state;
  }
};

export const key = 'profile';
export default reducer;
