export const types = {
  SET_ADDRESSES: 'SET_ADDRESSES',
  SET_FINAL_PRICE: 'SET_FINAL_PRICE',
  SET_CHECKOUT_LOADING: 'SET_CHECKOUT_LOADING',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_SELECTED_CHECKOUT_STEP: 'SET_SELECTED_CHECKOUT_STEP',
  SET_TAX: 'SET_TAX',
  SET_SHIPPING: 'SET_SHIPPING',
  SET_PICKUP: 'SET_PICKUP',
  SET_SELECTED_DELIVERY: 'SET_SELECTED_DELIVERY',
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
  SET_SELECTED_PAYMENT: 'SET_SELECTED_PAYMENT',
  SET_DISCOUNT: 'SET_DISCOUNT',
  SET_DISCOUNT_AMOUNT: 'SET_DISCOUNT_AMOUNT',
  SET_SHIPPING_LOADING: 'SET_SHIPPING_LOADING',
};

const actions = {
  setAddresses: (payload) => ({
    type: types.SET_ADDRESSES,
    payload,
  }),
  setFinalPrice: (payload) => ({
    type: types.SET_FINAL_PRICE,
    payload,
  }),
  setCheckoutLoading: (payload) => ({
    type: types.SET_CHECKOUT_LOADING,
    payload,
  }),
  setCountries: (payload) => ({
    type: types.SET_COUNTRIES,
    payload,
  }),
  setSelectedStep: (payload) => ({
    type: types.SET_SELECTED_CHECKOUT_STEP,
    payload,
  }),
  setTax: (payload) => ({
    type: types.SET_TAX,
    payload,
  }),
  setShipping: (payload) => ({
    type: types.SET_SHIPPING,
    payload,
  }),
  setPickup: (payload) => ({
    type: types.SET_PICKUP,
    payload,
  }),
  setSelectedDelivery: (payload) => ({
    type: types.SET_SELECTED_DELIVERY,
    payload,
  }),
  setPaymentMethod: (payload) => ({
    type: types.SET_PAYMENT_METHOD,
    payload,
  }),
  setSelectedPayment: (payload) => ({
    type: types.SET_SELECTED_PAYMENT,
    payload,
  }),
  setDiscount: (payload) => ({
    type: types.SET_DISCOUNT,
    payload,
  }),
  setDiscountAmount: (payload) => ({
    type: types.SET_DISCOUNT_AMOUNT,
    payload,
  }),
  setShippingLoading: (payload) => ({
    type: types.SET_SHIPPING_LOADING,
    payload,
  }),
};

export default actions;
