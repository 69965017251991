import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import MainContext from 'MainContext';

const RedirectAuthorized = (props) => {
  const {
    token: contextToken,
    setTokenContext,
    socket,
    setSocketContext,
  } = useContext(MainContext).login;
  const token = localStorage.getItem('layers-token');
  let authorized = false;
  if (token && token.length > 0) {
    authorized = true;
    axios.defaults.headers.common.Authorization = 'Bearer' + ' ' + token;
    if (!contextToken.length === 0) {
      setTokenContext(token);
    }
  }
  if (socket && !token) {
    socket.disconnect();
    setSocketContext(null);
  }
  return authorized === false ? props.children : <Redirect to={props.redirect} />;
};

export default RedirectAuthorized;
