import axios from 'axios';
const { REACT_APP_ORDERS_SERVICE_URL, REACT_APP_CHAT_SERVICE_URL, REACT_APP_UPLOAD_SERVICE_URL } = process.env;

export const getInvoices = async (reqData) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/invoice/user?page=${reqData.pageNumber}`)
    .then((response) => response.data.payload);
  return response;
};

export const getInvoice = async (id) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/invoice/${id}`)
    .then((response) => response.data.payload);
  return response;
};

export const getInvoiceProps = async (id) => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/admin/invoice/props/${id}`)
    .then((response) => response.data.payload);
  return response;
};

export const getOrders = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/admin/order/plural`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getComments = async (orderId) => {
  const response = await axios
    .get(`${REACT_APP_CHAT_SERVICE_URL}/chatroom/${orderId}`)
    .then((response) => response.data.payload);
  return response;
};

export const deletComment = async (orderId) => {
  const response = await axios
    .delete(`${REACT_APP_CHAT_SERVICE_URL}/chatroom/${orderId}`)
    .then((response) => response.data.payload);
  return response;
};

export const editComment = async (orderId, message) => {
  const data = { message };
  const response = await axios
    .put(`${REACT_APP_CHAT_SERVICE_URL}/chatroom/${orderId}`, data)
    .then((response) => response.data.payload);
  return response;
};

export const deleteNotification = async (orderId) => {
  const response = await axios
    .put(`${REACT_APP_CHAT_SERVICE_URL}/notification/${orderId}`)
    .then((response) => response.data.payload);
  return response;
};

export const uploadAttachedFile = async (data) => {
  const response = await axios
    .post(`${REACT_APP_UPLOAD_SERVICE_URL}/attachment`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getAttachedFiles = async (orderId) => {
  const response = await axios
    .get(`${REACT_APP_UPLOAD_SERVICE_URL}/attachment/${orderId}`)
    .then((response) => response.data.payload);
  return response;
}

export const downloadAttachedFiles = async (orderId, attachmentId) => {
  const response = await axios
    .get(`${REACT_APP_UPLOAD_SERVICE_URL}/attachment/${orderId}/${attachmentId}`, { responseType: 'blob' })
    .then((response) => response.data);
  return response;
}

export const deleteAttachment = async (attachmentId) => {
  const response = await axios
    .delete(`${REACT_APP_UPLOAD_SERVICE_URL}/attachment/${attachmentId}`)
    .then((response) => response.data.payload);
  return response;
}
