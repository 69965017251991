import axios from 'axios';
const {
  REACT_APP_AUTHENTICATION_SERVICE_URL,
  REACT_APP_ORDERS_SERVICE_URL,
  REACT_APP_PAYMENT_SERVICE_URL,
} = process.env;

export const getAddresses = async () => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/address`)
    .then((response) => response.data.payload);
  return response;
};

export const postAvatar = async (data) => {
  const response = await axios
    .put(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/profile/avatar`, data)
    .then((response) => response.data.payload);
  return response;
};
export const getTransactions=async()=>{
  const response=await axios
    .get(`${REACT_APP_PAYMENT_SERVICE_URL}/transaction`)
    .then((response) => response.data.payload);
  return response;
}