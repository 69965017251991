import React, { useState, createContext } from 'react';

// Create Context Object
export const CounterContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const CounterContextProvider = (props) => {
  const [cellphone, setCellphone] = useState('');
  const [showBasket, setShowBasket] = useState(false);
  const [showSetting, setShowSetting] = useState(false);

  return (
    <CounterContext.Provider
      value={{ cellphone, setCellphone, showBasket, setShowBasket, showSetting, setShowSetting }}
    >
      {props.children}
    </CounterContext.Provider>
  );
};
