import actions from './actions';
import { getCart } from './api';
import { getMaterials } from 'containers/slicer/container/api';
import { getInvoice } from 'containers/dashboard/container/api';

const cartPerformances = (dispatch) => {
  const getCartContext = async () => {
    try {
      const result = await getCart();
      if (result) {
        dispatch(actions.setBasketOrders(result.orders));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setCartContext = async (value) => {
    dispatch(actions.setBasketOrders(value));
  };
  const clearCartOrders=async()=>{
    dispatch(actions.setBasketOrders([]));
  }
  const getCheckoutInvoiceContext = async (id) => {
    try {
      const result = await getInvoice(id);
      if (result) {
        dispatch(actions.setCheckoutInvoice(result));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setCheckoutInvoice = async (value) => {
    dispatch(actions.setCheckoutInvoice(value));
  };

  const getMaterialsContext = async () => {
    try {
      const result = await getMaterials();
      if (result) {
        dispatch(actions.setMaterials(result.materials));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setLoadingCreateOrder = async (value) => {
    dispatch(actions.setLoadingCreateOrder(value));
  };

  return {
    getCartContext,
    setCartContext,
    getCheckoutInvoiceContext,
    setCheckoutInvoice,
    getMaterialsContext,
    setLoadingCreateOrder,
    clearCartOrders
  };
};

export default cartPerformances;
