import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import MainContext from 'MainContext';
import { ThemeContext } from 'context/themeContext';
import io from 'socket.io-client';
import jwt_decode from 'jwt-decode';

const AuthRequired = (props) => {
  const { darkThemeSelected, setDarkThemeSelected } = useContext(ThemeContext);
  const {
    token: contextToken,
    setTokenContext,
    socket,
    setSocketContext,
    getUserProfileContext,
    profile,
    notifications,
    getNotificationsContext,
    newNotificationReceived,
    storeLightTheme,
  } = useContext(MainContext).login;
  const { getCartContext } = useContext(MainContext).cart;

  const token = localStorage.getItem('layers-token');

  const setupSocket = () => {
    if (token && !socket) {
      const newSocket = io(process.env.REACT_APP_CHAT_SERVICE_URL, {
        query: {
          token: localStorage.getItem('layers-token'),
        },
      });

      newSocket.on('disconnect', () => {
        setSocketContext(null);
      });

      newSocket.on('connect', () => {
        let decoded = jwt_decode(token);
        newSocket.emit('joinRoom', {
          chatroomId: decoded.id,
        });
        setSocketContext(newSocket);
      });
      newSocket.on('newNotification', (notification) => {
        newNotificationReceived(notification);
      });

    }
  };

  

  useEffect(() => {
    setupSocket();
    getNotificationsContext();
  }, [token]);
  
  useEffect(() => {
    if (!profile?.first_name) {
      getUserProfileContext();
    } else {
      let darkModelHelper=!storeLightTheme;
      if (typeof profile.user_light_theme === 'boolean' ) {
        darkModelHelper=!profile.user_light_theme;
      }
      setDarkThemeSelected(darkModelHelper);
    }
  }, [profile]);

  

  let authorized = false;
  if (token && token.length > 0) {
    authorized = true;
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    if (!contextToken.length === 0) {
      setTokenContext(token);
    }
  }
  useEffect(() => {
    if (authorized) {
      getCartContext();
    }
  }, [axios.defaults.headers.common.Authorization]);
  return authorized === true ? props.children : <Redirect to={props.redirect} />;
};

export default AuthRequired;
