import axios from 'axios';
const { REACT_APP_AUTHENTICATION_SERVICE_URL, REACT_APP_CHAT_SERVICE_URL } = process.env;

export const getFamilyLogo = async () => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/login/logo`)
    .then((response) => response.data.payload);
  return response;
};

export const getFamilyBySubdomain = async (businessName) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/login/family-subdomain/${businessName}`)
    .then((response) => response.data.payload);
  return response;
};

export const checkUserRegistered = async (cellphone) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/login/${cellphone}`)
    .then((response) => response.data.payload);
  return response;
};

export const loginWithPassword = async (data) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/login/with-password`, data)
    .then((response) => response.data.payload);
  return response;
};

export const registerUser = async (data) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/register`, data)
    .then((response) => response.data.payload);
  return response;
};

export const confirmUser = async (data) => {
  const response = await axios
    .post(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/otp/confirmation`, data)
    .then((response) => response.data.payload);
  return response;
};

export const registerGuest = async ({ family }) => {
  const response = await axios
    .post(
      `${REACT_APP_AUTHENTICATION_SERVICE_URL}/register/guest`,
      {},
      {
        headers: {
          family,
        },
      }
    )
    .then((response) => response.data.payload);
  return response;
};

export const forgetPassword = async (email) => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/otp?cellphone=${email}`)
    .then((response) => response.data.payload);
  return response;
};

export const changePasswordReq = async (data) => {
  const response = await axios
    .put(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/profile/change-password`, data)
    .then((response) => response.data.payload);
  return response;
};

export const updateUserProfile = async (data) => {
  const response = await axios
    .put(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/profile`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getUserProfile = async () => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/profile`)
    .then((response) => response.data.payload);
  return response;
};

export const getPolicies = async () => {
  const response = await axios
    .get(`${REACT_APP_AUTHENTICATION_SERVICE_URL}/policy`)
    .then((response) => response.data.payload);
  return response;
};

export const getNotifications = async () => {
  const response = await axios
    .get(`${REACT_APP_CHAT_SERVICE_URL}/notification`)
    .then((response) => response.data.payload);
  return response;
};
