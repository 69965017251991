import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_ADDRESSES:
      return { ...state, addresses: action.payload };
    case types.SET_FINAL_PRICE:
      return { ...state, finalPrice: action.payload };
    case types.SET_CHECKOUT_LOADING:
      return { ...state, checkoutLoading: action.payload };
    case types.SET_COUNTRIES:
      return { ...state, countries: action.payload };
    case types.SET_SELECTED_CHECKOUT_STEP:
      return { ...state, selectedStep: action.payload };
    case types.SET_TAX:
      return { ...state, tax: action.payload };
    case types.SET_SHIPPING:
      return { ...state, shipping: action.payload };
    case types.SET_PICKUP:
      return { ...state, pickup: action.payload };
    case types.SET_SELECTED_DELIVERY:
      return { ...state, selectedDelivery: action.payload };
    case types.SET_PAYMENT_METHOD:
      return { ...state, payments: action.payload };
    case types.SET_SELECTED_PAYMENT:
      return { ...state, selectedPayment: action.payload };
    case types.SET_DISCOUNT:
      return { ...state, discount: action.payload };
    case types.SET_DISCOUNT_AMOUNT:
      return { ...state, discountAmount: action.payload };
    case types.SET_SHIPPING_LOADING:
      return { ...state, shippingLoading: action.payload };
    default:
      return state;
  }
};

export const key = 'checkout';
export default reducer;
