import axios from 'axios';
const { REACT_APP_UPLOAD_SERVICE_URL, REACT_APP_STL_SERVICE, REACT_APP_ORDERS_SERVICE_URL } =
  process.env;

export const getFiles = async () => {
  const response = await axios
    .get(`${REACT_APP_UPLOAD_SERVICE_URL}/upload`)
    .then((response) => response.data.payload);
  return response;
};

export const deleteFile = async (id) => {
  const response = await axios
    .delete(`${REACT_APP_UPLOAD_SERVICE_URL}/upload/${id}`)
    .then((response) => response.data);
  return response;
};

export const postFile = async (data, config) => {
  const response = await axios
    .post(`${REACT_APP_UPLOAD_SERVICE_URL}/upload`, data, config)
    .then((response) => response.data.payload);
  return response;
};



export const getVolumePrices = async (data) => {
  const response = await axios
    .post(`${REACT_APP_STL_SERVICE}/price/volume`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getTimePrices = async (data) => {
  const response = await axios
    .post(`${REACT_APP_STL_SERVICE}/price/time`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getQualityPrice = async (data) => {
  const response = await axios
    .post(`${REACT_APP_STL_SERVICE}/price`, data)
    .then((response) => response.data.payload);
  return response;
};

export const getMaterials = async () => {
  const response = await axios
    .get(`${REACT_APP_STL_SERVICE}/materials?page=1&limit=100&order=1&skip=0&sort=_id`)
    .then((response) => response.data.payload);
  return response;
};

export const createOrder = async (data) => {
  const response = await axios
    .post(`${REACT_APP_ORDERS_SERVICE_URL}/order`, data)
    .then((response) => response.data.payload);
  return response;
};
