import React, { useReducer,useEffect,useState } from 'react';
import MainContext from './MainContext';

import loginReducer, { key as loginKey } from 'containers/login/container/reducer';
import ordersReducer, { key as ordersKey } from 'containers/dashboard/container/reducer';
import toasterReducer, { key as toasterKey } from 'components/toaster/container/reducer';
import cartReducer, { key as cartKey } from 'containers/bascket/container/reducer';
import slicerReducer, { key as slicerKey } from 'containers/slicer/container/reducer';
import checkoutReducer, { key as checkoutKey } from 'containers/checkout/container/reducer';

import profileReducer, { key as profileKey } from 'containers/profile/container/reducer';

import LoginPerformances from 'containers/login/container/performances';
import OrdersPerformances from 'containers/dashboard/container/performances';
import ToasterPerformances from 'components/toaster/container/performances';
import CartPerformances from 'containers/bascket/container/performances';
import SlicerPerformances from 'containers/slicer/container/performances';
import CheckoutPerformances from 'containers/checkout/container/performances';

import ProfilePerformances from 'containers/profile/container/performances';

import combineReducer from 'utils/combineReducer';

const AppProvider = (props) => {
  
  const initialValue = {
    taskQueue:[],
    [loginKey]: {
      loginState: 1,
      loginType: 1,
      adminType: 1,
      language: 1,
      storeLightTheme: false,
      timeZone: 0,
      currency: 0,
      currencyToggleRightPos: 0,
      ceiling: 7,
      defaultCountryCheckoutId: 0,
      loadingAnonymous: false,
      profile: {},
      token: '',
      socket: null,
      notifications: [],
      email: '',
      redirect: '',
    },
    [ordersKey]: {
      invoices: [],
      totalInvoices: 0,
    },
    [cartKey]: {
      basketOrders: [],
      checkoutInvoice: [],
      materials: [],
      loadingCreateOrder: false,
    },
    [slicerKey]: {
      queedTasks:[],
      files: [],
      materials: [],
      selectedFiles: [
        {
          slicerId: 'a' + Math.random(),
          scaleXInitial: 0,
          scaleX: 0,
          scaleY: 0,
          scaleZ: 0,
          selectedStl: '',
          selectedStep: 1,
          volumePrices: [],
          volumePricesStatus: 'inactive',
          timePrices: [],
          timePricesStatus: 'inactive',
          selectedMaterial: '',
          selectedMaterialType: '',
          selectedColor: 'rgb(0,0,0)',
          selectedColorCode: '',
          selectedQuality: 1,
          selectedCode: '',
          selectedPrice: 0,
          selectedDeliveryTime: 0,
          quantity: 1,
          pricing_cache:{},
          modelExtension:'',
          qualities:{
              "1":{
                price:0,
                price_code:'',
                loading:false,
                infill:20,
                time:0,
                weight:0
              },
              "2":{
                price:0,
                price_code:'',
                loading:false,
                infill:20,
                time:0,
                weight:0
              },
              "3":{
                price:0,
                price_code:'',
                loading:false,
                infill:20,
                time:0,
                weight:0
              }
              
          },
          percentCompleted: -1,
          stlBlob: '',
          selectedColorObject: {},
          scaledStlPath:'',
          slicerDetail: {
            volume: 0,
            boundingBox: 0,
            density: 0,
            weight:0
          },
        },
      ],
    },
    [checkoutKey]: {
      addresses: [],
      finalPrice: 0,
      checkoutLoading: false,
      countries: [],
      selectedStep: 1,
      tax: 0,
      shipping: {},
      pickup: [],
      selectedDelivery: {
        type: '',
        shippingOrPickupId: '',
        optionId: '',
        price: 0,
      },
      payments: [],
      selectedPayment: '',
      discount: {},
      discountAmount: 0,
      shippingLoading: false,
    },
    [toasterKey]: {
      toasterMessages: [],
    },
    [profileKey]: {
      selectedTab: 0,
    },
  };

  const rootReducer = combineReducer({
    [loginKey]: loginReducer,
    [ordersKey]: ordersReducer,
    [cartKey]: cartReducer,
    [slicerKey]: slicerReducer,
    [checkoutKey]: checkoutReducer,
    [toasterKey]: toasterReducer,
    [profileKey]: profileReducer,
  });
  const [state, dispatch] = useReducer(rootReducer, initialValue);
  
  let [taskQueue,setTaskQueue]=useState([]);
  
  
  function postTask(task){
    setTaskQueue((prev)=>[...prev,task]);
  }

  const myInitialState = {
    ...state,
    postTask,
    [loginKey]: {
      ...state[loginKey],
      ...LoginPerformances(dispatch),
    },
    [ordersKey]: {
      ...state[ordersKey],
      ...OrdersPerformances(dispatch),
    },
    [cartKey]: {
      ...state[cartKey],
      ...CartPerformances(dispatch),
    },
    [slicerKey]: {
      ...state[slicerKey],
      ...SlicerPerformances(dispatch,state[slicerKey],state[loginKey].ceiling),
    },
    [checkoutKey]: {
      ...state[checkoutKey],
      ...CheckoutPerformances(dispatch),
    },
    [toasterKey]: {
      ...state[toasterKey],
      ...ToasterPerformances(dispatch),
    },
    [profileKey]: {
      ...state[profileKey],
      ...ProfilePerformances(dispatch),
    },
  };

  useEffect( ()=>{
    async function runATask(){
      if(taskQueue.length){
        let task=taskQueue[0];
        setTaskQueue(taskQueue.slice(1,taskQueue.length));
        await task(myInitialState);
      }
    }
    runATask();
  },[taskQueue])

  return (
    <MainContext.Provider displayName="Main Context" value={myInitialState}>
      {props.children}
    </MainContext.Provider>
  );
};

export default AppProvider;
