import React, { useState, createContext, useContext } from 'react';
import { CounterContext } from 'context/bestContext';

// Create Context Object
export const BackgroundContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const BackgroundContextProvider = (props) => {
  const [leftWidth, setLeftWidth] = useState(50);
  const [rotateAngle, setRotateAngle] = useState(30);
  const { showSidBar, setShowSidBar } = useContext(CounterContext);

  return (
    <BackgroundContext.Provider
      value={{
        leftWidth: showSidBar ? 0.8 * leftWidth : leftWidth,
        setLeftWidth,
        rotateAngle,
        setRotateAngle,
      }}
    >
      {props.children}
    </BackgroundContext.Provider>
  );
};
