import axios from 'axios';
const { REACT_APP_ORDERS_SERVICE_URL } = process.env;

export const getCart = async () => {
  const response = await axios
    .get(`${REACT_APP_ORDERS_SERVICE_URL}/order/cart`)
    .then((response) => response.data.payload);
  return response;
};

export const updateOrderQuantity = async (data) => {
  const response = await axios
    .put(`${REACT_APP_ORDERS_SERVICE_URL}/order/quantity`, data)
    .then((response) => response.data.payload);
  return response;
};

export const deleteOrder = async (id) => {
  const response = await axios
    .delete(`${REACT_APP_ORDERS_SERVICE_URL}/order/${id}`)
    .then((response) => response.data.payload);
  return response;
};
