import actions from './actions';
import toasterActions from 'components/toaster/container/actions';
import {
  getAddresses,
  getCountries,
  getTax,
  getShipping,
  getPickup,
  getPaymentMethod,
  getDiscount,
  changeOrderStatus
} from './api';

const checkoutPerformances = (dispatch) => {
  const setAddresses = async (value) => {
    dispatch(actions.setAddresses(value));
  };

  const setFinalPrice = async (value) => {
    dispatch(actions.setFinalPrice(value));
  };

  const setCheckoutLoading = async (value) => {
    dispatch(actions.setCheckoutLoading(value));
  };

  const getAddressesContext = async () => {
    try {
      const result = await getAddresses();
      if (result) {
        dispatch(actions.setAddresses(result.addresses));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getCountriesContext = async () => {
    try {
      const result = await getCountries();
      if (result) {
        dispatch(actions.setCountries(result.countries));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getTaxContext = async (data) => {
    try {
      const result = await getTax(data);
      if (result) {
        dispatch(actions.setTax(result.tax));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getShippingContext = async (data) => {
    try {
      dispatch(actions.setShippingLoading(true));
      const PickupResult = await getPickup();
      const result = await getShipping(data);
      if (result) {
        dispatch(actions.setShipping(result.shipping));
        dispatch(actions.setPickup(PickupResult.locations));
        dispatch(actions.setShippingLoading(false));
      }
    } catch (e) {
      dispatch(actions.setShippingLoading(false));
      console.error(e);
    }
  };

  const getPaymentMethodContext = async (data) => {
    try {
      const result = await getPaymentMethod();
      if (result) {
        dispatch(actions.setPaymentMethod(result));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getDiscountContext = async (discountCode) => {
    try {
      const result = await getDiscount(discountCode);
      if (result) {
        dispatch(actions.setDiscount(result.discount));
        //TODOTRANS
        dispatch(toasterActions.updateToaster({ type: 'success', message: 'Code approved' }));
      } else {
        dispatch(actions.setDiscount({}));
        //TODOTRANS
        dispatch(toasterActions.updateToaster({ type: 'error', message: 'Discound not exist' }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setSelectedStep = async (value) => {
    dispatch(actions.setSelectedStep(value));
  };

  const setSelectedDelivery = async (value) => {
    dispatch(actions.setSelectedDelivery(value));
  };

  const setSelectedPayment = async (value) => {
    dispatch(actions.setSelectedPayment(value));
  };

  const setDiscountAmount = async (value) => {
    dispatch(actions.setDiscountAmount(value));
  };

  const initializeCheckout = async () => {
    dispatch(actions.setSelectedStep(1));
    dispatch(actions.setDiscountAmount(0));
    dispatch(actions.setSelectedPayment(''));
    dispatch(
      actions.setSelectedDelivery({
        type: '',
        shippingOrPickupId: '',
        optionId: '',
        price: 0,
      })
    );
    dispatch(actions.setDiscount({}));
    dispatch(actions.setPaymentMethod([]));
    dispatch(actions.setShipping({}));
    dispatch(actions.setPickup([]));
    dispatch(actions.setTax(0));
    dispatch(actions.setAddresses([]));
  };

  const changeOrderStatusContext = async (orderId, data) => {
    try {
      const result = await changeOrderStatus(orderId, data);
      if (result) {
        // dispatch(actions.setOrderHubs(result))
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    setAddresses,
    setFinalPrice,
    setCheckoutLoading,
    getAddressesContext,
    getCountriesContext,
    getTaxContext,
    getShippingContext,
    getPaymentMethodContext,
    getDiscountContext,
    setSelectedStep,
    setSelectedDelivery,
    setSelectedPayment,
    setDiscountAmount,
    initializeCheckout,
    changeOrderStatusContext
  };
};

export default checkoutPerformances;
