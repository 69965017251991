export const languages = [
  { name: 'English', text: 'English', rtl: false, id: 0, value: 'en' },
  { name: 'Persian', text: 'Persian', rtl: true, id: 1, value: 'fa' },
  { name: 'French', text: 'French', rtl: false, id: 2, value: 'fr' },
  { name: 'Finnish', text: 'Finnish', rtl: false, id: 3, value: 'fi' },
  { name: 'Arabic', text: 'Arabic', rtl: true, id: 4, value: 'ar' },
  { name: 'Italian', text: 'Italian', rtl: false, id: 5, value: 'it' },
  { name: 'Korean', text: 'Korean', rtl: false, id: 6, value: 'ko' },
  { name: 'Slovak', text: 'Slovak', rtl: false, id: 7, value: 'sk' },
  { name: 'German', text: 'German', rtl: false, id: 8, value: 'de' },
  { name: 'Brazilian Portuguese', text: 'Brazilian Portuguese', rtl: false, id: 9, value: 'pt-BR' },
  { name: 'Russian', text: 'Russian', rtl: false, id: 10, value: 'ru' },
  { name: 'Armenian', text: 'Armenian', rtl: false, id: 11, value: 'arm' },
  { name: 'Danish', text: 'Danish', rtl: false, id: 12, value: 'da' },
  { name: 'Dutch', text: 'Dutch', rtl: false, id: 13, value: 'nl' },
  { name: 'Norwegian', text: 'Norwegian', rtl: false, id: 14, value: 'no' },
  { name: 'Estonian', text: 'Estonian', rtl: false, id: 15, value: 'et' },
  { name: 'Spanish', text: 'Spanish', rtl: false, id: 16, value: 'es' },
  { name: 'Swedish', text: 'Swedish', rtl: false, id: 17, value: 'sv' },
  { name: 'Turkish', text: 'Turkish', rtl: false, id: 18, value: 'tr' },
];

export const setLanguageByType = (name) => {
  let result;
  languages.map((language) => {
    if (name === language.name) {
      result = language.id;
    }
  });
  return result;
};

export const setLanguageById = (name) => {
  let result;
  languages.map((language) => {
    if (name === language.id) {
      result = language.name;
    }
  });
  return result;
};
