import { types } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_BASKET_ORDERS:
      return { ...state, basketOrders: action.payload };
    case types.SET_CHECKOUT_INVOICE:
      return { ...state, checkoutInvoice: action.payload };
    case types.SET_MATERIALS:
      return { ...state, materials: action.payload };
    case types.SET_LOADING_CREATE_ORDER:
      return { ...state, loadingCreateOrder: action.payload };
    default:
      return state;
  }
};

export const key = 'cart';
export default reducer;
