export const types = {
  SET_ADDRESSES: 'SET_ADDRESSES',
};

const actions = {
  setSelectedTab: (payload) => ({
    type: types.PROFILE_SET_SELECTED_TAB,
    payload,
  }),
};

export default actions;
