import React from 'react';
import styled from 'styled-components';
import LoadingBlueSVG from 'asset/loading-blue.svg';

const LazyLoadingIcon = styled.img.attrs({
  src: LoadingBlueSVG,
})`
  width: 7.079vw;
  height: 7.079vw;
`;

const LazyLoadingWrapper = styled.div`
  // position: fixed;
  position: ${(props) => (props.stripe ? 'none' : 'fixed')}
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? props.width : '100vw')};
  height: 100vh;
`;

export const LazyLoadingComponent = (props) => (
  <LazyLoadingWrapper width={props.width} position={props.stripe}>
    <LazyLoadingIcon />
  </LazyLoadingWrapper>
);

export const Space = styled.div`
  width: ${(props) => props.width + 'vw'};
`;

export const Wrapper = styled.div`
  position: relative;
  height: ${(props) => (props.modalStatus ? 'unset' : 'calc(100vh - 4.008vw)')};
  min-height: ${(props) => (props.modalStatus ? '39.823vw' : '49.823vw')};
  // overflow-y:auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const AutenticatedWrapper = styled.div`
  position: absolute;
  top: 0vw;
  left: 0vw;
  height: calc(100vh - 4.008vw);
  // width: calc(100vw - 11.97vw);
  width: 100vw;
  box-sizing: border-box;
  direction: ${(props) => (props.rtlDirection ? 'rtl' : 'ltr')};
`;

export const SecondNav = styled.div`
  position: relative;
  height: 4.893vw;
  width: 100vw;
  border-bottom: 1px solid #686868;
  padding-right: 1.561vw;
  padding-left: 1.561vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

export const HeightSpace = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;

export const ComponentsWrapper = styled.div`
  position: relative;
  height: ${(props) => props.height};
  width: calc(100% - 0.52vw);
  box-sizing: border-box;
  margin-top: ${(props) => props.marginTop && props.marginTop + 'vw'};
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0.624vw;
    border-radius: 0.624vw;
    background-color: ${(props) => props.theme.SecondaryBackground};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 0.26vw ${(props) => props.theme.SecondaryBackground};  */
    border-radius: 0.624vw;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.borderBackground};
    border: 0vw solid #f4f4f4;
    border-radius: 0.624vw;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.tagBorder};
  }
`;

export const SingleComponent = styled.div`
  position: relative;
  height: 3.123vw;
  width: 98%;
  border-radius: 4.164vw;
  box-sizing: border-box;
  // display: flex;
  display: grid;
  grid-template-columns: 3% 33% 8% 14% 8% 19% 16%;
  align-items: center;
  margin-top: 0.56vw;
  background-color: ${(props) => (props.selected ? props.theme.pure : props.theme.cardBackground)};
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.selected ? props.theme.pure : props.theme.cardOnCardBackground};
  }
  transition: 200ms ease-in-out;
`;

export const SingleClickWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 3.123vw;
  width: 34vw;
  border-radius: 4.164vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ElementWrapper = styled.div`
  width: ${(props) => props.width + 'vw'};
  color: ${(props) => props.theme.textPrimary};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  font-size: 0.937vw;
  font-weight: 500;
  display: ${(props) => (props.display ? props.display : 'flex')};
  align-items: center;
  justify-content: ${(props) => (props.align ? props.align : 'center')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Circle = styled.div`
  width: ${(props) => (props.size ? props.size : '2.394vw')};
  height: ${(props) => (props.size ? props.size : '2.394vw')};
  // background-color: ${(props) => props.colorCode};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.align ? props.align : 'center')};
  overflow: hidden;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const NotificationCircle = styled.div`
  position: relative;
  top: ${(props) => props.top};
  left: 1.04vw;
  height: ${(props) => props.width};
  width: ${(props) => props.width};
  border-radius: 50%;
  background-color: ${(props) => props.theme.baraRedColor};
  color: ${(props) => props.theme.textPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.56vw')};
  overflow: hidden;
`;
