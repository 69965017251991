import actions from './actions';
import { getInvoices,getAttachedFiles } from './api';
import axios from 'axios';

const ordersPerformances = (dispatch) => {
  const getInvoicesContext = async (data) => {
    try {
      const result = await getInvoices(data);
      if (result) {
        if (data.pageNumber === 1) {
          dispatch(actions.setInvoices(result.invoices));
        } else {
          dispatch(actions.updateInvoices(result.invoices));
        }
        dispatch(actions.setTotalInvoices(result.total));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const setAttachFiles = async (data) => {
    dispatch(actions.setAttachFiles(data));
  };
  const getAttachFileContext = async (id) => {
    try {
      const result = await getAttachedFiles(id);
      if (result) {
        dispatch(actions.setAttachFiles(result));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    getInvoicesContext,
    getAttachFileContext,
    setAttachFiles
  };
};

export default ordersPerformances;
