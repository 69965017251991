import React, { Suspense, lazy } from 'react';

import AuthRequired from 'containers/auth/auth-required';
import RedirectAuthorized from 'containers/auth/redirect-authorized';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import queryString from 'query-string';

import Background from 'containers/background';


import { CounterContextProvider } from 'context/bestContext';
import { ThemeContextProvider } from 'context/themeContext';
import { BackgroundContextProvider } from 'context/backgroundContext';
import AppProvider from './AppProvider';
import { LazyLoadingComponent } from 'components/wrapper';

import { handleLazyLoadError } from 'utils';

const Login = lazy(() => import('containers/login').catch(handleLazyLoadError));
const OTP = lazy(() => import('containers/otp').catch(handleLazyLoadError));
const Dashboard = lazy(() => import('containers/dashboard').catch(handleLazyLoadError));
const Slicer = lazy(() => import('containers/slicer').catch(handleLazyLoadError));
const Checkout = lazy(() => import('containers/checkout').catch(handleLazyLoadError));
const Sidebar = lazy(() => import('containers/sideBar').catch(handleLazyLoadError));
const Navbar = lazy(() => import('containers/navBar').catch(handleLazyLoadError));
const Bascket = lazy(() => import('containers/bascket').catch(handleLazyLoadError));
const Setting = lazy(() => import('containers/setting').catch(handleLazyLoadError));
const Toaster = lazy(() => import('components/toaster').catch(handleLazyLoadError));
const Profile = lazy(() => import('containers/profile').catch(handleLazyLoadError));
const Payment = lazy(() => import('containers/payment').catch(handleLazyLoadError));
const PaymentPaypalFailed = lazy(() => import('containers/payment/paypal/failed').catch(handleLazyLoadError));
const PaymentPaypalSuccess = lazy(() => import('containers/payment/paypal/success').catch(handleLazyLoadError));
const PaymentStripeStatus = lazy(() => import('containers/payment/stripe/status').catch(handleLazyLoadError));
const OrderGateway = lazy(() => import('containers/start').catch(handleLazyLoadError));
const FormSubmit = lazy(() => import('containers/form/submit').catch(handleLazyLoadError));

const App = () => (
  <ThemeContextProvider>
  <AppProvider>
    <Router>
      <CounterContextProvider>
          <BackgroundContextProvider>
            <Background>
              <Suspense fallback={<LazyLoadingComponent />}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <RedirectAuthorized redirect="/dashboard">
                        <Login />
                      </RedirectAuthorized>
                    )}
                  />
                  <Route
                    exact
                    path="/otp"
                    render={() => (
                      <RedirectAuthorized redirect="/dashboard">
                        <OTP />
                      </RedirectAuthorized>
                    )}
                  />
                  <Route
                    exact
                    path="/dashboard"
                    render={() => (
                      <AuthRequired redirect="/">
                        <Dashboard />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/dashboard/:invoiceId"
                    render={(props) => (
                      <AuthRequired redirect="/">
                        <Dashboard invoiceId={props.match.params.invoiceId} />
                      </AuthRequired>
                    )}
                  />
                  {/* ================= new route for invoice ============*/}
                  <Route
                    exact
                    path="/dashboard/invoice/:invoiceId"
                    render={(props) => (
                      <AuthRequired redirect="/">
                        <Dashboard invoiceId={props.match.params.invoiceId} />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/start"
                    render={() => (
                      <AuthRequired redirect="/">
                        <OrderGateway />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/form/submit"
                    render={() => (
                      <AuthRequired redirect="/">
                        <FormSubmit />
                      </AuthRequired>
                    )}
                  />
                  {/* ================= new route for invoice ============*/}
                  <Route
                    exact
                    path="/slicer"
                    render={() => (
                      <AuthRequired redirect="/">
                        <Slicer />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/checkout"
                    render={() => (
                      <AuthRequired redirect="/">
                        <Checkout />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/checkout/:invoiceId"
                    render={(props) => (
                      <AuthRequired redirect="/">
                        <Checkout invoiceId={props.match.params.invoiceId} />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/profile"
                    render={() => (
                      <AuthRequired redirect="/">
                        <Profile />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/payment/response"
                    render={(props) => (
                      <AuthRequired redirect="/">
                        <Payment match={queryString.parse(props.location.search)} />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/payment/response/paypal/failed/:invoiceId"
                    render={(props) => (
                      <AuthRequired redirect="/">
                        <PaymentPaypalFailed invoiceId={props.match.params.invoiceId} />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/payment/response/paypal/success/:invoiceId/:transactionId"
                    render={(props) => (
                      <AuthRequired redirect="/">
                        <PaymentPaypalSuccess
                          match={queryString.parse(props.location.search)}
                          invoiceId={props.match.params.invoiceId}
                          transactionId={props.match.params.transactionId}
                        />
                      </AuthRequired>
                    )}
                  />
                  <Route
                    exact
                    path="/payment/response/stripe/status/:invoiceId/:paymentMethodId/"
                    render={(props) => (
                      <AuthRequired redirect="/">
                        <PaymentStripeStatus invoiceId={props.match.params.invoiceId} paymentMethodId={props.match.params.paymentMethodId} />
                      </AuthRequired>
                    )}
                  />
                </Switch>
                <Navbar />
                <Bascket />
                <Setting />
                <Toaster />
              </Suspense>
            </Background>
          </BackgroundContextProvider>
      </CounterContextProvider>
    </Router>
  </AppProvider>
  </ThemeContextProvider>
);
export default App;
