import actions from './actions';
import toasterActions from 'components/toaster/container/actions';
import { getAddresses } from './api';

const profilePerformances = (dispatch) => {
  const setSelectedTabContext = async (value) => {
    dispatch(actions.setSelectedTab(value));
  };

  return {
    setSelectedTabContext,
  };
};

export default profilePerformances;
