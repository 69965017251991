import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files

import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationFI from './locales/fi/translation.json';
import translationAR from './locales/ar/translation.json';
import translationIT from './locales/it/translation.json';
import translationKO from './locales/ko/translation.json';
import translationSK from './locales/sk/translation.json';
import translationDE from './locales/de/translation.json';
import translationPTBR from './locales/pt-BR/translation.json';
import translationRU from './locales/ru/translation.json';
import translationFA from './locales/fa/translation.json';
import translationARM from './locales/arm/translation.json';
import translationDA from './locales/da/translation.json';
import translationNL from './locales/nl/translation.json';
import translationNO from './locales/no/translation.json';
import translationET from './locales/et/translation.json';
import translationES from './locales/es/translation.json';
import translationSV from './locales/sv/translation.json';
import translationTR from './locales/tr/translation.json';

//Creating object with the variables of imported translation files
const resources = {
  en: { translation: translationEN },
  fr: { translation: translationFR },
  fi: { translation: translationFI },
  ar: { translation: translationAR },
  it: { translation: translationIT },
  ko: { translation: translationKO },
  sk: { translation: translationSK },
  de: { translation: translationDE },
  'pt-BR': { translation: translationPTBR },
  ru: { translation: translationRU },
  fa: { translation: translationFA },
  arm: { translation: translationARM },
  da: { translation: translationDA },
  nl: { translation: translationNL },
  no: { translation: translationNO },
  et: { translation: translationET },
  es: { translation: translationES },
  sv: { translation: translationSV },
  tr: { translation: translationTR },
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: 'fa', //default language
  // keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});
