export const types = {
  LOGIN_STATE: 'LOGIN_STATE',
  SET_LOGIN_TYPE: 'SET_LOGIN_TYPE',
  SET_ADMIN_TYPE: 'SET_ADMIN_TYPE',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_STORE_LIGHT_THEME: 'SET_STORE_LIGHT_THEME',
  SET_TIME_ZONE: 'SET_TIME_ZONE',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_CURRENCY_TOGGLE_RIGHT_POS: 'SET_CURRENCY_TOGGLE_RIGHT_POS',
  SET_CEILING: 'SET_CEILING',
  SET_DEFAULT_COUNTRY_CHECKOUT_ID: 'SET_DEFAULT_COUNTRY_CHECKOUT_ID',
  SET_LOADING_ANONYMOUS: 'SET_LOADING_ANONYMOUS',
  SET_PROFILE: 'SET_PROFILE',
  SET_TOKEN: 'SET_TOKEN',
  SET_SOCKET: 'SET_SOCKET',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_EMAIL: 'SET_EMAIL',
  SET_REDIRECT: 'SET_REDIRECT',
  NEW_NOTIFICATION_RECIEVED:'NEW_NOTIFICATION_RECIEVED'

};

const actions = {
  setLoginState: (payload) => ({
    type: types.LOGIN_STATE,
    payload,
  }),
  setLoginType: (payload) => ({
    type: types.SET_LOGIN_TYPE,
    payload,
  }),
  setAdminType: (payload) => ({
    type: types.SET_ADMIN_TYPE,
    payload,
  }),
  setLanguage: (payload) => ({
    type: types.SET_LANGUAGE,
    payload,
  }),
  setStoreLightTheme: (payload) => ({
    type: types.SET_STORE_LIGHT_THEME,
    payload,
  }),
  setTimeZone: (payload) => ({
    type: types.SET_TIME_ZONE,
    payload,
  }),
  setCurrency: (payload) => ({
    type: types.SET_CURRENCY,
    payload,
  }),
  setCurrencyToggleRightPos: (payload) => ({
    type: types.SET_CURRENCY_TOGGLE_RIGHT_POS,
    payload,
  }),
  setCeiling: (payload) => ({
    type: types.SET_CEILING,
    payload,
  }),
  setDefaultCountryCheckoutId: (payload) => ({
    type: types.SET_DEFAULT_COUNTRY_CHECKOUT_ID,
    payload,
  }),
  setLoadingAnonymous: (payload) => ({
    type: types.SET_LOADING_ANONYMOUS,
    payload,
  }),
  setProfile: (payload) => ({
    type: types.SET_PROFILE,
    payload,
  }),
  setToken: (payload) => ({
    type: types.SET_TOKEN,
    payload,
  }),
  setSocket: (payload) => ({
    type: types.SET_SOCKET,
    payload,
  }),
  setNotifications: (payload) => ({
    type: types.SET_NOTIFICATIONS,
    payload,
  }),
  newNotificationReceived: (payload) => ({
    type: types.NEW_NOTIFICATION_RECIEVED,
    payload,
  }),
  setEmail: (payload) => ({
    type: types.SET_EMAIL,
    payload,
  }),
  setRedirect: (payload) => ({
    type: types.SET_REDIRECT,
    payload,
  }),
};

export default actions;
